import React from "react";
import "./style.css";
import Logo from "../../../../assets/icons/main_logo.svg";
import KamalMitraLogo from "../../../../assets/images/kamal-mitra-logo.png";
import { authSelector } from "../../../../redux/slicers/authSlice";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/helpIcon.svg";
import { ReactComponent as DownArrow } from "../../../../assets/icons/down-arrow.svg";
import { useState } from "react";
import { logoutApi } from "../../../../api/studentApi";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../../utils/projectHelper";

const NavBar = ({ role = "guest" }) => {
  const { userDetails = {} } = useSelector(authSelector);
  const { firstName = "", lastName = "", userType = "" } = userDetails;
  const [profileDropdown, setProfileDropdown] = useState(false);
  const fullName = firstName + " " + lastName;

  const USERTYPE = getUserDetails();
  const isAdmin = USERTYPE?.firstName == "support";
  console.log(isAdmin);

  const handleLogout = () => {
    logoutApi();
    setProfileDropdown(false);
  };

  return (
    <nav>
      <div className="student-nav">
        <div className="logo-wrapper">
          <div className="logo">
            <img src={Logo} alt="logo" />
          </div>

          {/* <div className="kamal-logo">
            <img src={KamalMitraLogo} alt="logo" />
          </div> */}
        </div>

        <div
          className="dropdown"
          onClick={(e) => e.stopPropagation()}
          style={{ position: "relative", top: 15 }}
        >
          <p onClick={() => setProfileDropdown(!profileDropdown)}>
            {fullName}
            <span>
              <DownArrow />
            </span>
          </p>
          <div
            className="dropdown-options"
            id="drop-down"
            style={{ display: profileDropdown ? "block" : "none" }}
          >
            <div className="option">
              <p className="check-admin">{userType}</p>
              <p className="logout-admin" onClick={handleLogout}>
                <span>
                  <HelpIcon />
                </span>
                Log out
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
