import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: {},
    userDetails: {},
  },
  reducers: {
    setUserInfo: (state, { payload }) => {
      state.userInfo = payload;
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
  },
});

export const { setUserInfo, setUserDetails } = authSlice.actions;

export const authSelector = (state) => state.auth;
const authReducer = authSlice.reducer;
export default authReducer;
