import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  ADMIN_SIGNIN,
  FORGOT_PASSWORD,
  OTP,
  REGISTER,
  RESET_PASSWORD,
  SIGNIN,
} from "../../../config/constants/routePathConstant";
import Footer from "./footer";
import NavBar from "./navbar";
import SideBar from "./SideBar";
import './style.css'

const noLayout = [REGISTER, FORGOT_PASSWORD, RESET_PASSWORD, OTP];

const AdminLayout = () => {
  const { pathname = "" } = useLocation();
  const hasLayout = noLayout.find((item) => pathname.includes(item));
  return hasLayout || pathname == SIGNIN ? (
    <Outlet />
  ) : (
    <div className="admin-dashboard">
      <NavBar />
      <div className="main-dashboard">
        <div className="main-dashboar-layout">
          <div className="main-dashboard-header">
            <h1>Welcome back</h1>
            <p>Dashboard Overview</p>
          </div>
          <div className="main-layout">
            <SideBar />
            <Outlet />
          </div>
          
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
