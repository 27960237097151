import { ASSESSMENT_ID } from "./projectConstant";

export const DEV = "http://localhost:5000/";
export const PROD = "https://api-master.testaonline.com/";
export const STAGE = "https://api-master.testaonline.com/";
export const API_ROOT = PROD;

export const ADMIN_SIGNIN_API = "api/loginuser";
export const SIGNIN_API = "api/login";
export const SIGNUP_API = "api/registerCandidate";
export const FORGOT_PASSWORD_API = "api/sendOTP";
export const VERIFY_OTP_API = "api/verifyOtp";
export const RESET_PASSWORD_API = "api/reset-password";

export const RE_ARRANGE_TIME_SLOT_API = "api/re-arrange-time-slot";
export const CHECK_TEST_ATTENDED_API = "api/check-test-attended";
export const GET_STATES_API = "api/getStates";
export const GET_DISTRICTS_API = "api/getcities";
export const GET_SLOT_LIST_API = "api/slot-list";
export const CAPTURE_IMAGE_API = "api/captureImage";
export const GET_QUESTIONS_API = `api/getDemoquestions?test_id=${ASSESSMENT_ID}`;
export const SUBMIT_ASSESSMENT_API = "api/takeExam";
export const GET_ASSESSMENT_LIST_API = "api/assesment-list";
export const GET_REPORT_DETAILS_API = "api/reportDetails";
export const POST_TEST_START_TIME_API =
  "api/tests/646c48d9e100278ba18316b4/start";
export const GET_TEST_START_TIME_API =
  "api/tests/646c48d9e100278ba18316b4/start-time?candidateId=";
export const GET_COUNTDOWN_TIMER_API = "api/get-countimer";

export const FETCH_REPORT_API = "api/fetch-reportBy-date";
export const REGISTER_CANDIDATE_LIST_API = "api/register-candidate-list";
// export const GET_COUNTS = "api/test-lite/get-candidate-count"
export const GET_COUNTS = "api/all-client-counts";
export const GET_TESTA_LITE_COUNTS = "api/test-lite/get-candidate-count";
export const GET_KSDC_COUNTS = "api/ksdc/get-candidate-count";
export const GET_ALL_CANDIDATES_BY_CLIENTS = "api/all-candiates-by-clients";
