import { combineReducers, configureStore } from "@reduxjs/toolkit";
import guestReducer from "./slicers/guestSlice";
import authReducer from "./slicers/authSlice";
import studentReducer from "./slicers/studentSlice";
import activityReducer from "./slicers/activitySlice";


const rootReducer = combineReducers({
  guest: guestReducer,
  auth: authReducer,
  student: studentReducer,
  activity:activityReducer,
})

const store = configureStore({ reducer: rootReducer });
export default store;
