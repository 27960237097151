import { SUB_ADMIN, SUPER_ADMIN, WEBSITE_RELOGIN } from "./routePathConstant";

export const DEFAULT_TOKEN = "token";
export const USER_DATA = "userData";
export const USER_DETAILS = "user_detils";

// export const GOOGLE_REVIEW_URL = "https://www.google.com/search?q=radiantinfonet&rlz=1C1RXQR_enIN1066IN1067&oq=radiantinfonet&aqs=chrome..69i57j46i10i175i199i512j69i64j0i10i512j69i60l2.5400j0j7&sourceid=chrome&ie=UTF-8#lrd=0x390d1e7a852350c1:0xffde863ff786f13c,3";
export const GOOGLE_REVIEW_URL = "https://www.google.com/search?q=Radiant+Infonet+(P)+Limited+%7C+Online+Assessment+Software&stick=H4sIAAAAAAAA_-NgU1I1qDC2NEgxTDVPtDA1MjY1SDa0MqhIS0tJtTAzTksztzBLMzROXsRqEZSYkpmYV6LgmZeWn5daoqARoKngk5mbWZKaolCj4J-Xk5mXquBYXJxaXJybClQXnJ9WUp5YlAoAjkRromQAAAA&hl=en&mat=CTvKcx-v0TCqElYBeenfiIi4GGSkrSToHYIBFSoBHcqfSrF2uo18vS6_Rznz8al5OlqWlhUBicHhhimUHXxZxf28N7EZFjI-aFb2XrndZOAB-Ng3iC3ROAJgGVWdtIqlIw&authuser=1#lrd=0x390d1e7a852350c1:0xffde863ff786f13c,3";

//roles
export const SUPER_ADMIN_ROLE = "super-admin";

//role navigation
export const ROLE_NAV = {
  ADMIN: SUPER_ADMIN,
  SUBADMIN: SUB_ADMIN,
};

export const PREVENT_ROUTES = [WEBSITE_RELOGIN];

export const NETWORK_ERROR = "Network Error";
export const ASSESSMENT_ID = "648c5eb59dd7695c94635da5";

// Languages
export const LANGUAGES = [
  { label: "English", value: "English" },
  { label: "Hindi", value: "Hindi" },
  { label: "Marathi", value: "Marathi" },
  { label: "Gujarati", value: "Gujarati" },
  { label: "Odiya", value: "Odiya" },
  { label: "Tamil", value: "Tamil" },
  { label: "Telugu", value: "Telugu" },
  { label: "Bengali", value: "Bengali" },
];

//States

export const STATES = [
  {
    label: "Andaman and Nicobar Islands",
    value: "Andaman and Nicobar Islands",
  },
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    label: "Assam",
    value: "Assam",
  },
  {
    label: "Bihar",
    value: "Bihar",
  },
  {
    label: "Chandigarh",
    value: "Chandigarh",
  },
  {
    label: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    value: "Dadra and Nagar Haveli and Daman and Diu",
  },
  {
    label: "Delhi",
    value: "Delhi",
  },
  {
    label: "Goa",
    value: "Goa",
  },
  {
    label: "Gujarat",
    value: "Gujarat",
  },
  {
    label: "Haryana",
    value: "Haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "Himachal Pradesh",
  },
  {
    label: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
  },
  {
    label: "Jharkhand",
    value: "Jharkhand",
  },
  {
    label: "Karnataka",
    value: "Karnataka",
  },
  {
    label: "Kerala",
    value: "Kerala",
  },
  {
    label: "Ladakh",
    value: "Ladakh",
  },
  {
    label: "Lakshadweep",
    value: "Lakshadweep",
  },
  {
    label: "Madhya Pradesh",
    value: "Madhya Pradesh",
  },
  {
    label: "Maharashtra",
    value: "Maharashtra",
  },
  {
    label: "Manipur",
    value: "Manipur",
  },
  {
    label: "Meghalaya",
    value: "Meghalaya",
  },
  {
    label: "Mizoram",
    value: "Mizoram",
  },
  {
    label: "Nagaland",
    value: "Nagaland",
  },
  {
    label: "Odisha",
    value: "Odisha",
  },
  {
    label: "Puducherry",
    value: "Puducherry",
  },
  {
    label: "Punjab",
    value: "Punjab",
  },
  {
    label: "Rajasthan",
    value: "Rajasthan",
  },
  {
    label: "Sikkim",
    value: "Sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "Tamil Nadu",
  },
  {
    label: "Telangana",
    value: "Telangana",
  },
  {
    label: "Tripura",
    value: "Tripura",
  },
  {
    label: "Uttar Pradesh",
    value: "Uttar Pradesh",
  },
  {
    label: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    label: "West Bengal",
    value: "West Bengal",
  },
];