import { lazy } from "react";
import StudentLayout from "../../components/common/layout/StudentLayout";
import { STUDENT_ASSESSMENT_PAGE } from "./../constants/routePathConstant";
const studentRoutes = [
  {
    path: "/",
    element: <StudentLayout />,
    children: [
      {
        path: "*",
        element: <h1>404</h1>,
      },
    ],
  },
];

export default studentRoutes;
