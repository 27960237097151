import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  ADMIN_SIGNIN,
  FORGOT_PASSWORD,
  OTP,
  REGISTER,
  RESET_PASSWORD,
  SIGNIN,
} from "../../../config/constants/routePathConstant";
import Footer from "./footer";
import NavBar from "./navbar";

const noLayout = [REGISTER, FORGOT_PASSWORD, RESET_PASSWORD, OTP, ADMIN_SIGNIN];

const StudentLayout = () => {
  const { pathname = "" } = useLocation();
  const hasLayout = noLayout.find((item) => pathname.includes(item));
  return hasLayout || pathname == SIGNIN ? (
    <Outlet />
  ) : (
    <div className="student-page">
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default StudentLayout;
