export const WEBSITE_RELOGIN = "/re-login"
export const SUPER_ADMIN = "/super-admin"
export const SUB_ADMIN = "/sub-admin";


//Auth Routes
// export const WEBSITE = "/";
export const ADMIN_SIGNIN = "/";
export const SIGNIN = "/";
export const REGISTER = "/register";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const OTP = "/otp";

// Capture Face
export const STUDENT_CAPTURE_FACE_PAGE = "/capture-face";
export const STUDENT_START_ASSESSMENT_PAGE = "/start-assessment";
export const STUDENT_GENERAL_INSTRUCTIONS_PAGE = "/general-instructions";
export const STUDENT_ASSESSMENT_PAGE = "/assessment";
export const STUDENT_SUCCESS_PAGE = "/success";
export const STUDENT_RESULT_PAGE = "/result";
export const STUDENT_COUNT_DOWN_PAGE = "/count-down";
export const STUDENT_RESCHEDULE_PAGE = "/reschedule";
export const STUDENT_TIME_OUT_PAGE = "/time-out";

// OverallLists
export const DASHBOARD_OVERALL_VIEW = "/dashboard"

// Particulat client 
// export const PARTICULAR_CLIENT_VIEW = '/candidate-registration-today'

export const STUDENT_CANDIDATE_REGISTRATION_PAGE_TODAY = "/dashboard/candidate-registration-today";
export const STUDENT_CANDIDATE_RESULTS_PAGE_TODAY = "/dashboard/candidate-results-today";
export const STUDENT_CANDIDATE_RESULTS_PAGE = "/dashboard/candidate-results";
export const STUDENT_CANDIDATE_REGISTRATION_PAGE = "/dashboard/candidate-registration";