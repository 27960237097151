import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LazyLoader from "./components/common/LazyLoader";
import { getRoutes } from "./config/routes";
import { authSelector, setUserDetails } from "./redux/slicers/authSlice";
import { getUserDetails } from "./utils/projectHelper";
import ActivityContainer from "./container/Activity";

function App() {
  const { userDetails } = useSelector(authSelector);
  const dispatch = useDispatch();
  const userData= getUserDetails()
  
  useEffect(() => {
    dispatch(setUserDetails(userData));
  }, []);
  
  const routeType = userDetails?.userType == "admin" ? 3 : 3;
  
  const router = useRoutes(getRoutes(routeType));

  return (
    <>
      <ActivityContainer>
        <LazyLoader>{router}</LazyLoader>
        <ToastContainer />
      </ActivityContainer>
    </>
  );
}

export default App;
