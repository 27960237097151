import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";

//skeleton
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { ReactComponent as DesktopIcon } from "../../../../assets/icons/desktop_icon.svg";
import { ReactComponent as FocusIcon } from "../../../../assets/icons/focus_icon.svg";
import { ReactComponent as RecoveryIcon } from "../../../../assets/icons/time_rewind_icon.svg";
import { LinearProgress } from "@mui/material";
import { activitySelector } from "../../../../redux/slicers/activitySlice";
import { useSelector } from "react-redux";
import { authSelector } from "../../../../redux/slicers/authSlice";
import Gauge from "../../guageChart/guageChart";

const SideBar = () => {
  const menuState = useSelector(activitySelector);
  const { renderDecider = {} } = useSelector(authSelector);
  return (
    <>
      <div
        className={`dashboard-leftsidebar ${
          menuState.isMenuOpen ? "show" : ""
        }`}
      >
        <ul>
          {!renderDecider.length > 0 ? (
            <>
              <div className="sidebar-gauge-box">
                <Gauge value={909} min={0} max={1000} />

                <button>live streaming</button>

                <hr />

                <p>
                  It seems like a lot is going on and you probably get tired
                  quickly
                </p>
              </div>
              <li className="sidebar-list workspace">
                <div className="title-wrap">
                  <DesktopIcon />
                  <h3 className="title">workplace stress</h3>
                </div>
                <div className="progress">
                  <LinearProgress variant="determinate" value={70} />
                </div>
                <p className="description">
                  You don't seem to be too excited about your job, do you?
                </p>
              </li>
              <li className="sidebar-list focus">
                <div className="title-wrap">
                  <FocusIcon />
                  <h3 className="title">Focus</h3>
                </div>
                <div className="progress">
                  <LinearProgress variant="determinate" value={45} />
                </div>
                <p className="description">
                  It's time to amp up your rejuvenation game as stress
                  drastically affects your productivity.
                </p>
              </li>
              <li className="sidebar-list recovery">
                <div className="title-wrap">
                  <RecoveryIcon />
                  <h3 className="title">Recovery</h3>
                </div>
                <div className="progress">
                  <LinearProgress variant="determinate" value={55} />
                </div>
                <p className="description">
                  You have a relatively low recovery level.
                </p>
              </li>
            </>
          ) : (
            <div className="permission_loader">
              <Stack
                spacing={2}
                sx={{
                  marginTop: 1,
                }}
              >
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
                <Skeleton
                  sx={{ bgcolor: "#f7f8fb" }}
                  variant="rounded"
                  width={200}
                  height={60}
                />
              </Stack>
            </div>
          )}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
