import { lazy } from "react";
import AdminLayout from "../../components/common/layout/adminLayout";
import {
  STUDENT_CANDIDATE_RESULTS_PAGE,
  STUDENT_CANDIDATE_REGISTRATION_PAGE,
  ADMIN_SIGNIN,
  STUDENT_CANDIDATE_RESULTS_PAGE_TODAY,
  STUDENT_CANDIDATE_REGISTRATION_PAGE_TODAY,
  DASHBOARD_OVERALL_VIEW,
} from "./../constants/routePathConstant";
import { Link } from "react-router-dom";

const CandidateResults = lazy(() =>
  import("../../pages/admin/candidateManagement/candidateResults")
);
const CandidateRegistration = lazy(() =>
  import("../../pages/admin/candidateManagement/candidateRegistration")
);
const CandidateList = lazy(() =>
  import("../../pages/admin/candidateManagement/candidateTable")
);
const NotFound = lazy(() => import("../../pages/notFound"));

const AdminLogin = lazy(()=> import("../../pages/admin/login"))

const Guest = () => {
  return (
    <div>
      <h1>Hello World (Guest)</h1>
      <Link to={ADMIN_SIGNIN}>Go to Signin</Link>
    </div>
  );
};

const adminRoutes = [
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        element: <AdminLogin/>,
      },
      {
        path: DASHBOARD_OVERALL_VIEW,
        element: <CandidateList />
      },
      {
        path: STUDENT_CANDIDATE_RESULTS_PAGE_TODAY,
        element: <CandidateList />,
      },
      {
        path: STUDENT_CANDIDATE_REGISTRATION_PAGE_TODAY,
        element: <CandidateList />,
      },
      {
        path: STUDENT_CANDIDATE_RESULTS_PAGE,
        element: <CandidateList />,
      },
      {
        path: STUDENT_CANDIDATE_REGISTRATION_PAGE,
        element: <CandidateList />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
];

export default adminRoutes;
